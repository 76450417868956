import { LOGGY_URL } from "../constant";
import Cookies from "universal-cookie";
import { isEmpty, uuidv4 } from "../components/shared/custom_html_elements/utils";

const session_id_generator = () => {
  return "xxxxxxxxxxxx4xxx".replace(/x/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const session_id = () => {
  const storedId = sessionStorage.getItem("session_id");
  if (storedId) return storedId;
  const sessionId = session_id_generator();
  sessionStorage.setItem("session_id", sessionId);
  return sessionId;
};

export const pushLoggyEvent = (event_data) => {
  const cookies = new Cookies();
  const url = LOGGY_URL;
  let user = cookies.get("currentUser");
  user = (user && user.data && user.data.data && user.data.data.user) || {};
  const user_id = user && user.id;
  const user_role = user && user.roles && user.roles[0];
  let payload = {
    ...event_data,
    origin: "clients_ui",
    application_name: "clients_ui",
    event_at: new Date().toJSON(),
    session_id: session_id(),
  };
  if (!isEmpty(user)) {
    payload = { user_id, user_role, ...payload };
  }
  const request = fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json", "X-Request-Id": `clients_ui_${uuidv4()}`, X_CLIENT_ID: "clients_ui", caller_id: "business-ui" },
    keepalive: true,
  });
  return request;
};
