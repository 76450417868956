import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../custom_html_elements/utils";
import { Link } from "react-router-dom";
import { logout } from "../../../actions";
import { LOGO_ICON_WHITE } from "../../../constant";
import Dropdown from "react-bootstrap/Dropdown";
import { LinkContainer } from "react-router-bootstrap";
import history from "../../../history";
import "./sidebar.scss";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    const bottomSwitchLinks = [
      {
        icon: "https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/svgs/settings.svg",
        name: "Settings",
        url: `/${this.props.match.params.domain}/settings`,
        ext: false,
      },
      { icon: "https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/svgs/members.svg", name: "Members", url: `/${this.props.match.params.domain}/members`, ext: false },
    ];

    this.state = {
      sidebarLinks: [],
      bottomSwitchLinks: bottomSwitchLinks,
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    this.setProjectDependentSidebarLinks();
    if (!isEmpty(currentUser)) {
      document.getElementById("root").style.marginLeft = "61px";
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser?.id !== this.props.currentUser?.id) {
      this.setProjectDependentSidebarLinks();
      if (!isEmpty(this.props.currentUser)) {
        document.getElementById("root").style.marginLeft = "61px";
      }
    }
  }

  componentWillUnmount() {
    document.getElementById("root").style.marginLeft = "0px";
  }

  setProjectDependentSidebarLinks = () => {
    let sidebarLinks = [];
    if (this.props.match.params.project_id) {
      sidebarLinks = [
        {
          icon: "https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/svgs/analytics.svg",
          name: "Analytics",
          url: `/${this.props.match.params.domain}/requirements/${this.props.match.params.requirement_id}/client/projects/${this.props.match.params.project_id}/executions/${this.props.match.params.execution_id}/roles/${this.props.match.params.role_name}/analytics`,
          active: false,
          ext: false,
        },
        {
          icon: "https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/svgs/data.svg",
          name: "Data",
          url: `/${this.props.match.params.domain}/requirements/${this.props.match.params.requirement_id}/client/projects/${this.props.match.params.project_id}/executions/${this.props.match.params.execution_id}/roles/${this.props.match.params.role_name}/leads`,
          active: true,
          ext: false,
        },

        // {
        //   icon: "https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/svgs/reports.svg",
        //   name: "Reports",
        //   url: `/${this.props.match.params.domain}/requirements/${this.props.match.params.requirement_id}/projects/${project._id}/reports`,
        //   active: false,
        //   ext: false,
        // },
        // {
        //   icon: "https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/svgs/invoice.svg",
        //   name: "Invoice",
        //   url: `/${this.props.match.params.domain}/requirements/${this.props.match.params.requirement_id}/projects/${project._id}/invoices`,
        //   active: false,
        //   ext: false,
        // },
        // {
        //   icon: "https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/svgs/psettings.svg",
        //   name: "Settings",
        //   url: `/${this.props.match.params.domain}/requirements/${this.props.match.params.requirement_id}/settings`,
        //   active: false,
        //   ext: false,
        // },
      ];
    }
    this.setState({ sidebarLinks });
  };

  callLogout = () => {
    this.props.logout().then(() => {
      history.push("/");
    });
  };

  render() {
    const { currentUser } = this.props;
    const roles = (currentUser && currentUser.roles) || [];
    if (currentUser) {
      return (
        <div className="sidenav collapsed" id="sidenav">
          <div className="sidenav-links">
            <div className="sidenav-inner-items">
              <Link to={`/`} key={"logo"}>
                <div className="sidebar-logo">
                  <img src={LOGO_ICON_WHITE} alt="Logo" />
                </div>
              </Link>
              {!isEmpty(this.props.project) &&
                this.state.sidebarLinks.map((item, index) => {
                  return (
                    <Link to={item["url"]} key={index} className={item["name"] === this.props.sidebarActive ? "selected" : ""}>
                      <div className="sidebar-img-div">
                        <img src={item["icon"]} alt={item["name"]} />
                        <span>{item["name"]}</span>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
          {currentUser && !roles.includes("int-ce") && (
            <div className="sidebar-switch">
              <Dropdown drop="right">
                <Dropdown.Toggle variant="transparent" className="text-white" id="dropdown-switch">
                  <Link to="#" className="org-switch-icon">
                    <img src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/ic_org_white.svg" alt="" />
                  </Link>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.bottomSwitchLinks.map((item, index) => {
                    if (!item["ext"]) {
                      return (
                        <LinkContainer key={index} to={item["url"]}>
                          <Dropdown.Item>
                            <div className="sidebar-img-div">
                              <img className="header-icons" src={item["icon"]} alt={item["name"]} />
                            </div>
                            {item["name"]}
                          </Dropdown.Item>
                        </LinkContainer>
                      );
                    } else {
                      return (
                        <a href={item["url"]} className="dropdown-item" key={index} target="_blank" rel="noopener noreferrer">
                          <div className="sidebar-img-div">
                            <img className="header-icons" src={item["icon"]} alt={item["alt"]} />
                          </div>
                          {item["name"]}
                        </a>
                      );
                    }
                  })}
                  {!isEmpty(currentUser.memberships) && currentUser.memberships.length > 1 && (
                    <Dropdown.Item key="all-org" href="/">
                      <div className="sidebar-img-div">
                        <img src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/ic_all_org_dark.svg" alt="all-org" />
                      </div>{" "}
                      All Organisation
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.login.currentUser,
    project: state.projects.project,
    sidebarActive: state.common.sidebarActive,
  };
}

export default connect(mapStateToProps, {
  logout,
})(Sidebar);
